import React from 'react';

import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import Layout from '../../../components/MenuLayout';

import { EditUser } from '../../../components/User/Edit/EditUser';
import { EditPatientUser } from '../../../components/User/Edit/EditPatientUser';
import { getUserInterface } from '../../../utils/patientUserHospitalUtils';
import { GET_USER_FOR_EDITING } from '../../../graphql/queries';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';
import { AlertContainerUI, AlertUI } from '../../../componentsUI/Alert';

export default () => {
  const { data, error, loading } = useQuery(GET_USER_FOR_EDITING);
  const { t } = useTranslation();
  const userInterface = getUserInterface();
  const EditComponent = userInterface === 'patient' ? EditPatientUser : EditUser;

  const messageError = networkErrorParse(error);
  const user = data && data.user && {
    uuid: data.user.uuid,
    name: data.user.name,
    surname: data.user.surname,
    personalId: data.user.personalId,
    professionalRole: data.user.professionalRole,
    specialty: data.user.specialty,
    subspecialty: data.user.subspecialty === '-' ? '' : data.user.subspecialty,
    collegiateNumber: data.user.collegiateNumber,
    profilePhoto: data.user.profilePhoto,
  };

  if (messageError) {
    return (
      <AlertContainerUI>
        <AlertUI severity="error" title="Error">{t(messageError)}</AlertUI>
      </AlertContainerUI>
    );
  }

  return (
    <Layout type="userSettings">
      {!loading && user && <EditComponent user={user} />}
    </Layout>
  );
};
